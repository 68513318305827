@charset "utf-8";

/*Some bulma colors/styles that are overridden by providing them before the other files
*/

@import "bulmaOverridden";
// Import our Google Font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400&display=swap');
// Import only what we need from Bulma
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/layout/_all.sass";

/*
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/form.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
*/

//TODO check these global styles

// Font size - how to make it responsive?
html {
  font-size: 14px;
  @include tablet {
    font-size: 16px;
  }
  @include desktop {
    font-size: 18px;
  }
}



//change the section title/subtitle spacing and colours depending on background

// get title and subtitle (or anything else) spacing to breath a bit
// todo V9 can use spacing helpers instead

.has-space-below {
  padding-bottom: 1em;
}

.has-dbl-space-below {
  padding-bottom: 2em;
}

.has-dbl-space-above {
  padding-top: 2em;
}

//normal colours for white background

.section.has-background-white {
  .title, .subtitle {
    color: $primary;
    margin-bottom: 1.5em;
  }
  /*
  a {
    color: $primary;
    &:hover {
      color: $textgrey;
    }
  }
  h3, p, ul, ol {
    color: $textgrey;
  }
  h3 {
    margin-bottom: 1.5em
  }
  */
}

//colour override for coloured backgrounds
.section.has-background-primary, .section.has-background-success, .section.has-background-info, .section.has-background-danger {
  .title, .subtitle {
    color: $white;
    margin-bottom: 1.5em;
  }
  // don't override message body text colour
  ul, ol, div:not(.message-body) {
    color: $white;
  }
  a {
    color: $grey-lightest;
    &:hover {
      color: $textgrey;
    }
  }
  .content h1, .content h2, .content h3 {
    color: $white;
  }
}


//general loading whirlygig
.loading {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;

    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}

//hand cursor
.hand {
  cursor: pointer;
}

//This is used when we need an icon on one of our buttons e.g. open in new window
/*
<div class="buttons" >
  <a class="button is-white is-outlined is-radiusless">
    <span>Sign in</span>
    <span class="buttonicon">
      <font-awesome-icon  :icon="['fal', 'external-link-alt']" />
    </span>
  </a>
</div>
*/

.buttonicon {
  align-items: flex-start;
  display:inline-flex;
  justify-content: center;
  font-size: 0.6rem !important;
  padding-left: 0.5rem;
  height: 1rem;
}

// Set size images
/*
As (nearly all of) our images are held in Contentful we can use their image API scaling to get the right size to output
to our page no matter the input size. For example text panel images are set in the layout to be 128px high but variable
width. Assuming we want to support retina screens we need to supply an image that is 256px vertical resolution and let
the browser display it as 128 "device independent pixels".
In contentful we call the image API with the following query string ?fit=pad&height=256


BANNERS
The maximum size of banner pictures are:
desktop 960
tablet 916
mobile 684

However in portrait orientation:
tablet devices have typical banner width of about 680-920px
mobile devices have typical banner width of about 240-350px

So we supply three srcset picture sizes:
- image size 1920 wide
- image size 960 wide
- image size 640 wide

For BANNERS UPLOAD an image 1920 by 640

 */

.is-128high {
  height: 128px;

    img {
    max-width: 100%;
    width: auto;
  }
}

// To overcome issue of sticky menu - see https://github.com/jgthms/bulma/issues/2514
@media screen and (min-width: 1025px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: none;
  }
}


// add in our own version of the Bulma "content" element to style Contentful content
@import "./content";


//add in SchemaView specific styles - this is all a bit crude as the libraries used have built-in styles

.json-schema-view {

  font-family: monospace;
  font-size: $body-font-size;
  display: table-cell;
  color: $textgrey;

  .json-formatter-key {
    color: $grey-light !important;
  }

  .json-formatter-bracket {
    color: $grey-light !important;
  }

  .json-formatter-number {
    color: $grey-light !important;
  }

  .json-formatter-constructor-name {
    color: $grey-light !important;
  }
  .json-formatter-toggler {
    color: $grey-light !important;
  }

  .inner.enums b {
    font-weight: normal !important;
    color: $grey-light !important;
  }

  /*> * {*/
  /*  font-size: @font-size;*/
  /*}*/

  .toggle-handle {
    cursor: pointer;
    margin: auto .3em;
    font-size: 10px;
    display: inline-block;
    transform-origin: 50% 40%;
    transition: transform 150ms ease-in;

    &:after {
      content: "▼";
    }

    &, &:hover {
      text-decoration: none;
      color: $grey-light;
    }
  }

  .description {
    color: gray;
    font-style: italic;
  }

  .title {
    //font-weight: bold;
    cursor: pointer;
    &, &:hover {
      text-decoration: none;
      color: $primary;
    }
  }

  .title, .brace, .bracket {
    color: $black;
    font-weight: normal;
    font-size: inherit;
  }

  .property {
    font-size: inherit;
    display: table-row;
  }

  .name {
    color: $primary;
    display: table-cell;
    vertical-align: top;
  }

  .type {
    color: $warning;
  }

  .type-any {
    color: lighten($success, 10%);
  }

  .required {
    color: $warning;
  }

  .format, .enums, .pattern {
    color: $textgrey;
  }

  .inner {
    padding-left: 1em;
  }

  &.collapsed {
    .description {
      display: none;
    }
    .property {
      display: none;
    }
    .closing.brace {
      display: inline-block;
    }
    .toggle-handle {
      transform: rotate(-90deg);
    }
  }
}

