
  @import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

  .is-162x46 {
    @include desktop {
      height: 46px;
      width: 162px;
    }

    /*

        @include mobile {
          height: 23px;
          width: 81px;
        }
    */
  }


