/*Some bulma colors/styles that are overridden by providing them before the other files
*/

@charset "utf-8";

@import "node_modules/bulma/sass/utilities/initial-variables.sass";

// Set our brand colors
$orange: #F7762E; //primary
$green: #9DCC93; //success
$tealblue: #7BB5BF; //info
$purple: #511966; //warning
$red: #C51A1B; //danger
$textgrey: #6B6B6B;

// Update Bulma's global variables
$family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
$primary: $orange;
$info: $tealblue;
$warning: $purple;
$link: $orange;
$text: $textgrey;
$body-weight: $weight-light;
//$grey-dark: $brown;
//$grey-light: $beige-light;
//$link: $pink;

//normal font size (standard Bulma is 16px)
//See html override in style.css

// The container horizontal gap, which acts as the offset for breakpoints
//$gap: 64px;

// Update some of Bulma's component variables
//$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$footer-padding: 6rem 1.5rem 1.5rem;

$navbar-breakpoint: $tablet;
$navbar-background-color: $primary;
$navbar-height: 4.5rem;
$navbar-item-img-max-height: 3.5rem;
$navbar-item-hover-background-color: lighten($primary,10);
$navbar-item-color: $white;
$navbar-item-hover-color: $black;
$navbar-dropdown-background-color: $primary;
$navbar-dropdown-item-hover-background-color: lighten($primary,10);

//menu - lighten background colour when selected to match navbar
$menu-item-active-background-color: lighten($primary,10);

$footer-background-color: $white;

$control-border-width: 1px;

// Disable the widescreen andd fullhd breakpoints to constrain the screen max width
$widescreen-enabled: false;
$fullhd-enabled: false;
// So we have mobile up to 768 and tablet up to 1023 and desktop (1215 max) for everything bigger
