

@import "@/scss/_bulmaOverridden";


.Cookie--untangl {
  color: $white;
  background-color: $textgrey;
  height: auto;
  padding: 1em;
  /*margin: 1em;*/

  a {
    color: $grey-lighter;
  }
}

.spaced {
  padding-left: 1em;
  padding-right: 1em;
  margin: auto 0 auto 0;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
}

